<template>
  <div class="page">
    <!-- 搜索栏 -->
    <van-sticky>
      <van-search
        v-model="keyword"
				shape="round"
        :placeholder="placeholder"
        @search="onRefresh"
        clearable
      />
    </van-sticky>
    <van-tabs v-model="tabIndex" @click="change_tab" color="#ff5595" sticky :offset-top="53" :line-width="20">
      <van-tab :title="item.title" :name="index" v-for="(item,index) in tab" :key="index">
        <!-- 筛选栏 -->
				<van-sticky :offset-top="96">
					<div class="filter_bar">
						<div
							class="filter_item"
							@click="filter_change(0)"
							:class="{filter_active:filterIndex===0}"
						>
							<span>综合</span>
						</div>
						<div
							class="filter_item"
							@click="filter_change(1)"
							:class="{filter_active:filterIndex===1}"
						>
							<span>销量</span>
							<img src="@/assets/common/tjf_gzh_sptj_jg_nor_icon.png" alt />
							<img
								class="asc_price"
								src="@/assets/common/tjf_gzh_sptj_jg_down_pre_icon.png"
								alt
								v-if="sort_sales=='asc'"
							/>
							<img
								class="des_price"
								src="@/assets/common/tjf_gzh_sptj_jg_up_pre_icon.png"
								alt
								v-if="sort_sales=='des'"
							/>
						</div>
						<div
							class="filter_item"
							@click="filter_change(2)"
							:class="{filter_active:filterIndex===2}"
						>
							<span>价格</span>
							<img src="@/assets/common/tjf_gzh_sptj_jg_nor_icon.png" alt />
							<img
								class="asc_price"
								src="@/assets/common/tjf_gzh_sptj_jg_down_pre_icon.png"
								alt
								v-if="sort_price=='asc'"
							/>
							<img
								class="des_price"
								src="@/assets/common/tjf_gzh_sptj_jg_up_pre_icon.png"
								alt
								v-if="sort_price=='des'"
							/>
						</div>
						<div class="filter_item" @click="_filterClick">
							<span style="color:#ff6184" v-if="isShowpopup">筛选</span>
							<span style="color:#ff6184" v-else-if="show_filter==true&&isShowpopup==false">筛选</span>
							<span style="color:#ff6184" v-else-if="show_filter">筛选</span>
							<span v-else>筛选</span>
							<img
								src="@/assets/common/tjf_gzh_sptj_jg_up_pre_icon.png"
								class="down_arrows"
								alt
								v-if="isShowpopup"
							/>
							<img
								src="@/assets/common/tjf_gzh_sptj_jg_down_pre_icon.png"
								class="down_arrows"
								alt
								v-else-if="show_filter==true&&isShowpopup==false"
							/>
							<img
								src="@/assets/common/tjf_gzh_sptj_jg_up_pre_icon.png"
								class="down_arrows"
								alt
								v-else-if="show_filter"
							/>

							<img src="@/assets/common/tjf_gzh_sptj_sx_nor_icon.png" class="down_arrows" alt v-else />
						</div>
						<van-popup v-model="isShowpopup" position="top">
							<p>价格区间(元)</p>
							<div class="search_scope">
								<input type="number" placeholder="最低价" class="input_class" v-model="start_price" />
								<div class="partition"></div>
								<input
									type="number"
									placeholder="最高价"
									class="input_class"
									v-model="end_price"
									id="end_price"
									@keyup.enter="affirm"
								/>
							</div>
							<div class="handle_btn">
								<van-button
									class="clear_btn"
									@click="start_price='',end_price='',show_filter = false;"
								>重置</van-button>
								<van-button class="confirm_btn" @click="affirm">确定</van-button>
							</div>
						</van-popup>
					</div>
				</van-sticky>
        <!-- 商品列表 -->
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            @load="_getData()"
            v-model="loading"
            :finished="finished"
            :offset="200"
            finished-text="没有更多了"
          >
            <div
              class="goods-item"
              v-for="(tab, index) in item.data"
              :key="index"
              @click="_onItemClick(tab)"
            >
              <img v-lazy="tab.pict_url" class="goods-image" alt />
              <div class="goods-info">
                <div>
                  <div class="goods-title">{{tab.goods_title}}</div>
                  <div class="sale-volumn">
                    <span>已售{{tab.volume}}件</span>
                  </div>
                  <div class="goods-price">
                    <div class="show-price">
                      <span>券后</span>
                      <span class="zk-price">¥{{tab.price}}</span>
                      <span class="orginal_price" v-if="!!tab.reserve_price">¥{{tab.reserve_price}}</span>
                    </div>
                  </div>
                </div>
                <div class="goods-ext">
                  <div>
                    <div class="goods-coupon" v-if="parseFloat(tab.coupon_amount)>0">
                      <span>{{parseFloat(tab.coupon_amount)}}元券</span>
                    </div>
                  </div>
                  <div>
                    <div class="share-amount">
                      预估收益
                      <span>¥{{tab.commission}}</span>
                    </div>
                  </div>
                </div>
                <div class="shop_title">{{tab.shop_title}}</div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vue from "vue";
import StatusBar from "@/components/StatusBar";
import NavBar from "@/components/NavBar";
import ls from "@/libs/localstore";
import {
  List,
  CellGroup,
  Cell,
  Tab,
  Tabs,
  PullRefresh,
  Lazyload,
  Search,
  Button,
	Popup,
	Sticky
} from "vant";
import { searchProducts } from "@/api/shop";
Vue.use(List)
  .use(CellGroup)
  .use(Cell)
  .use(Tabs)
  .use(Tab)
  .use(List)
  .use(Lazyload)
  .use(PullRefresh)
  .use(Search)
	.use(Button)
	.use(Sticky)
  .use(Popup);

export default {
  data() {
    return {
      filter_bar: ["综合", "销量", "价格", "筛选"],
      tab: [
        {
          title: "淘宝",
          platform: "tb",
          toPage: 1,
          data: [],
        },
        {
          title: "京东",
          platform: "jd",
          toPage: 1,
          data: [],
        },
        {
          title: "拼多多",
          platform: "pdd",
          toPage: 1,
          data: [],
				},
				{
          title: "唯品会",
          platform: "vip",
          toPage: 1,
          data: [],
				},
				{
          title: "考拉",
          platform: "kl",
          toPage: 1,
          data: [],
        },
      ],
      keyword: "",
      filterIndex: 0,
      tabIndex: 0,
      loading: false,
      finished: false,
      refreshing: false,
      placeholder: "复制标题，搜隐藏优惠券拿返利",
      /* 筛选栏 */
      sort_sales: null,
      sort_price: null,
      query_sort: null,
      query_params: {},
      isShowpopup: false,
      show_filter: false,
      start_price: "",
      end_price: "",
    };
  },
  mounted() {
    this.$toast.loading({
      message: "加载中...",
      duration: 0,
      loadingType: "spinner",
      forbidClick: true,
    });
    this._initParams();
	},
	watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.name == 'GoodsRecommend' && to.query.keyword != this.keyword) {
				this._initParams()
				this.onRefresh()
      }
    }
  },
  methods: {
    //初始化筛选参数
    _initParams() {
      this.sort_price = null;
      this.sort_sales = null;
      this.show_filter = false;
      this.isShowpopup = false;
      this.start_price = "";
      this.end_price = "";
      this.keyword = this.$route.query.keyword;
      let params = {
        keyword: this.$route.query.keyword,
        sort: null,
        start_price: this.start_price,
        end_price: this.end_price,
      };

      this.$nextTick(() => {
        this.query_params = params;
      });
    },
    //价格筛选
    affirm() {
      let params = {
        start_price: this.start_price,
        end_price: this.end_price,
      };
      Object.assign(this.query_params, params);
      this.isShowpopup = false;
      this.show_filter = true;
      this.filterIndex = 3;
      document.getElementById("end_price").blur();
      this.onRefresh();
      //清空其他平台数据
      this.tab.forEach((data, index) => {
        this.tab[index].data = [];
      });
    },
    //筛选tab切换
    filter_change(index) {
      this.filterIndex = index;
      if (index == 1) {
        this._sortBySales();
      } else if (index == 2) {
        this._sortByPrice();
      } else if (index == 0) {
        this._initParams();
        this.$toast.loading({
          message: "加载中...",
          duration: 0,
          loadingType: "spinner",
          forbidClick: true,
        });
        setTimeout(() => {
          this.onRefresh();
        }, 100);
      }
    },
    //筛选价格
    _filterClick() {
      this.isShowpopup = !this.isShowpopup;
    },
    //刷新
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.tab[this.tabIndex].toPage = 1;
      this.tab[this.tabIndex].data = [];
      this._getData();
    },
    //商品详情页面
    _onItemClick(item) {
      this.$router.push({ path: "/goodsDetail", query: { id: item.goods_id } });
    },
    //tab栏切换
    change_tab() {
      if (this.tab[this.tabIndex].data.length <= 0) {
        this.onRefresh();
      }
    },
    // 销量排序
    _sortBySales() {
      this.isShowpopup = false;
      this.sort_price = null;
      if (this.sort_sales === null) this.sort_sales = "des";
      else if (this.sort_sales === "des") this.sort_sales = "asc";
      else if (this.sort_sales === "asc") this.sort_sales = "des";
      if (this.sort_sales !== null)
        this.query_params.sort = "total_sales_" + this.sort_sales;
      else this.query_params.sort = null;
      //清空其他平台数据
      this.tab.forEach((data, index) => {
        this.tab[index].data = [];
      });
      this.onRefresh();
    },
    // 价格排序
    _sortByPrice() {
      this.isShowpopup = false;
      this.sort_sales = null;
      if (this.sort_price === null) this.sort_price = "des";
      else if (this.sort_price === "des") this.sort_price = "asc";
      else if (this.sort_price === "asc") this.sort_price = "des";
      if (this.sort_price !== null)
        this.query_params.sort = "price_" + this.sort_price;
      else this.query_params.sort = null;
      //清空其他平台数据
      this.tab.forEach((data, index) => {
        this.tab[index].data = [];
      });
      this.onRefresh();
    },
    //获取数据
    _getData() {
      if (this.refreshing) {
        this.tab[this.tabIndex].data = [];
        this.refreshing = false;
      }
      let params = {
         
        keyword: this.keyword,
        platform: this.tab[this.tabIndex].platform,
        toPage: this.tab[this.tabIndex].toPage,
        rows: 10,
        sort: this.query_params.sort,
      };
      if (
        this.query_params.start_price !== undefined &&
        this.query_params.start_price !== "" &&
        this.query_params.start_price !== null
      ) {
        params.start_price = this.query_params.start_price;
      }
      if (
        this.query_params.end_price !== undefined &&
        this.query_params.end_price !== "" &&
        this.query_params.end_price !== null
      ) {
        params.end_price = this.query_params.end_price;
      }
      searchProducts(params)
        .then((res) => {
          if (res.errCode == 0 && res.data.length > 0) {
            this.tab[this.tabIndex].data = this.tab[this.tabIndex].data.concat(
              res.data
            );
            console.log(res.data);
            this.tab[this.tabIndex].toPage++;
            this.loading = false;
          } else {
            this.finished = true;
          }
          this.$toast.clear();
          this.loading = false;
        })
        .catch((err) => {
          this.$toast.clear();
          this.loading = false;
          this.finished = true;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #ffffff;
  line-height: 1;
}
.search {
	width: 100%;
}
.goods-item {
  height: 156.5px;
  padding: 12.5px 19px 12.5px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: #ffffff;
}

.goods-image {
  flex: none;
  width: 130px;
  max-height: 130px;
  border-radius: 10px;
  margin-right: 15px;
}
.goods-info {
  flex: 1;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.goods-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.show-price {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(255, 80, 80, 1);
  .orginal_price {
    margin-left: 6px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: line-through;
    color: rgba(153, 153, 153, 1);
  }
}

.zk-price {
  font-size: 17px;
  margin-left: 2px;
}
.sale-volumn {
  margin: 10px 0 12px;
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(132, 132, 132, 1);
}

.goods-title {
  position: relative;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  height: 35px;
  width: 192px;
  -webkit-box-orient: vertical;
}

.goods-ext {
  display: flex;
  justify-content: space-between;
}
.shop_title {
  font-size: 12px;
  color: #9A9A9A;
  line-height: 1;
  font-weight: 400;
}

.share-amount {
  display: flex;
  align-items: center;
  height: 17px;
  background: rgba(255, 237, 241, 1);
  border-radius: 4px;
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(17, 5, 10, 1);
  padding: 0 4px 0 5px;
}

.share-amount span {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 80, 80, 1);
}

.goods-coupon {
  height: 17px;
  background: rgba(255, 80, 80, 1);
  border-radius: 3.5px;
  line-height: 17px;
  color: #ffffff;
  font-size: 11px;
  padding: 0 4px;
}
.filter_bar {
  top: 92px;
  padding: 10px 0 12.5px;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .filter_item {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    text-align: center;
    flex: 1;
    position: relative;
    img {
      top: 3px;
      width: 6px;
      height: 8px;
      left: 65%;
      position: absolute;
    }
    .down_arrows {
      height: 4px;
      top: 5px;
    }
    .asc_price {
      height: 3px;
      top: 8px;
    }
    .des_price {
      height: 3px;
      top: 3px;
    }
  }
  .filter_active {
    color: #ff6184;
  }
}
.van-popup--top,
.van-overlay {
  top: 130px;
}
.van-popup {
  box-sizing: border-box;
  height: 146.5px !important;
  border-radius: 0px 0px 15px 15px;
  padding: 10px 19px 20px;
  p {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(99, 99, 99, 1);
  }
  .search_scope {
    display: flex;
    align-items: center;
    margin: 11px 0 20px;
    .partition {
      width: 18px;
      height: 1px;
      background: rgba(233, 233, 233, 1);
      margin: 0 11px;
    }
    .input_class {
      font-family: PingFang SC;
      font-weight: 400;
      width: 130px;
      height: 33px;
      line-height: 33px;
      background: rgba(246, 246, 246, 1);
      border-radius: 16.5px;
      font-size: 13px;
      color: rgba(51, 51, 51, 1);
      text-align: center;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .input_class::-webkit-input-placeholder {
      font-size: 12px;
      color: rgba(188, 188, 188, 1);
      display: inline-block;
    }
  }
  .handle_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .van-button {
      width: 163px;
      height: 40px;
      line-height: 40px;
      position: none;
      text-align: center;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      background: rgba(245, 245, 245, 1);
      border-radius: 6.5px;
    }
    .confirm_btn {
      background: linear-gradient(
        270deg,
        rgba(255, 108, 115, 1),
        rgba(255, 85, 149, 1)
      );
      color: #ffffff;
    }
  }
}
</style>
